import { DateTimePicker } from '../src/rad_common_js/dateTimePicker';
import { Patients } from './patients';

export class Orders {
  static setup() {
    Orders.setupForm();
    Orders.setupCharts();
    Orders.setupIndex();
    Orders.setupOrderConsentForm();
    Orders.setupActivitySelect($('form.edit_sub_activity'));
  }

  static setupIndex() {
    $("#area_report_id").change(function() {
      const data = $("#area_report_id").val();
      return $.ajax({
        type: 'POST',
        url: '/orders/fill_activity_report',
        data: { area_id: data },
        error(jqXHR, textStatus, errorThrown) {
          return console.log("AJAX Error");
        },
        success(data, textStatus, jqXHR) {
          if (data && (data !== "")) {
            $("#activity_report_id").html(data);
          }
          return console.log("AJAX working!");
        }
      });
    });

    $("#activity_report_id").change(function() {
      const data = $("#activity_report_id").val();
      $.ajax({
        type: 'POST',
        url: '/orders/fill_sub_activity_report',
        data: {activity_id: data},
        error(jqXHR, textStatus, errorThrown) {
          return console.log("AJAX Error for sub activity");
        },
        success(data, textStatus, jqXHR) {
          if (data && (data !== "")) {
            $("#sub_activity_report_id").html(data);
          }
          return console.log("AJAX working for sub activity!");
        }
      });
    });

    $('#area_report_id').change();

    if ($("#confirmed_by_customer_at").length) {
      document.getElementById('confirmed_by_customer_at').valueAsDate = new Date;
    }

    $(".check_all").click(function() {
      return Orders.select_all_column_checkboxes( $(this), true );
    });

    $(".check_none").click(function() {
      return Orders.select_all_column_checkboxes( $(this), false );
    });

    const date_range = $("#date_range");
    const hours = $("#hours");

    date_range.hide();

    $("#order_type").change(function() {
      if ($(this).val() === 'open') {
        date_range.hide();
        return hours.show();
      } else {
        hours.hide();
        return date_range.show();
      }
    });
  }

  static setupForm(form = null) {
    const $form = form || $('form.new_order, form.edit_order');
    const $formSubmitBtn = $form.find('input[type=submit]');

    if ($form.length == 0) {
      return false;
    }

    Orders.setupActivitySelect($form);
    Orders.maybeShowConsentGatheringDetails($form);

    if (!Orders.requireOrderOutcome($form)) {
      Orders.toggleOrderOutcomeField($form, false);
    }

    $form.find('#order_closed').on('change', () => Orders.toggleOrderOutcomeField($form, Orders.requireOrderOutcome($form)));

    $form.find('#order_order_outcome_id').on('focusin', function() {
      $(this).data('val', $(this).val());
    });
    $form.find('#order_order_outcome_id').on('change', Orders.confirmClosure($form));

    $('#order_prescriber_id').data('original-value', $('#order_prescriber_id').val());
    $form.find('#order_prescriber_id').change(function() {
      const prescriberId = $(this).val();
      if (prescriberId == $('#order_prescriber_id').data('original-value')) {
        return false;
      }

      $('#order_prescriber_id').data('original-value', prescriberId);
      Orders.showPrescriberLocation($form, prescriberId);

      return $.ajax({
        type: 'POST',
        url: '/orders/fill_prescriber_location',
        data: { prescriber_id: prescriberId },
        success(data) {
          if (data && (data !== '')) {
            $('.order_prescriber_location_id #order_prescriber_location_id').remove();
            $('.order_prescriber_location_id').append(data);
          }
        }
      });
    });

    $form.find('#order_order_type_id, #order_order_type_id').change(function() {
      const hide_treatment_type = $(this).find('option:selected').text() !== 'New';

      $form.find('#order-treatment-type-col').toggleClass('d-none', hide_treatment_type);
      $form.find('#order_treatment_type').prop('disabled', hide_treatment_type);
    });

    $form.find('#order_activity_id').change(function() {
      Orders.maybeShowConsentGatheringDetails($form);

      if ($(this).hasClass('skip-basket-validation')) {
        return null;
      }

      const activity = $(this).find('option:selected');
      const removeBasket = activity.data('has-basket') == false && $form.find('#order_basket_number').val() != '';
      const removeTag = activity.data('has-tag') == false && $form.find('#order_tag_number').val() != '';

      if (removeBasket || removeTag) {
        const prompt = `This activity does not require a${removeBasket ? ' basket' : ''}${ removeBasket && removeTag ? ' /' : ''}${removeTag ? ' tag' : ''} would you like to remove it?`;
        if (window.confirm(prompt)) {
          if (removeBasket) {
            if ($form.find('#order_basket_number').length > 0) {
              $form.find('#order_basket_number').val('');
            } else {
              $(this).after(`<input id="order_basket_number" name="order[basket_number]" type="hidden" value="">`)
            }
          }
          if (removeTag) {
            if ($form.find('#order_tag_number').length > 0) {
              $form.find('#order_tag_number').val('');
            } else {
              $(this).after(`<input id="order_tag_number" name="order[tag_number]" type="hidden" value="">`)
            }
          }
        }
      }
    });

    Orders.toggleRefillFields($form, $form.find('#order_auto_refills').prop('checked'));

    Orders.showPrescriberLocation($form, $form.find('#order_prescriber_id').val());

    $form.find('#order_auto_refills').click(function() {
      Orders.toggleRefillFields($form, $(this).prop('checked'));
      return Orders.toggleOrderOutcomeField($form, Orders.requireOrderOutcome($form));
    });

    $form.on('submit', function(e) {
      if ($form.find('#order_consent_provided').val() != '') {
        return null;
      }

      e.preventDefault();
      $formSubmitBtn.prop('disabled', true);
      DateTimePicker.convertDateTimeInputs();

      $.ajax({
        url: $form.data('consent-url'),
        type: 'GET',
        data: $form.serialize(),
        success(data) {
          if (data.message) { // consent not required
            $form.find('#order_consent_provided').val(false);
            if ($form.data('remote') == true) {
              $formSubmitBtn.prop('disabled', false);
            } else {
              $form.submit();
            }
          }
        },
        error() {
          $formSubmitBtn.prop('disabled', false);
        }
      });
    });
  }

  static setupActivitySelect(form) {
    if (form.find('select.activity-selector').length == 0) {
      return null;
    }

    Orders.fill_activities(form);
    Orders.fill_sub_activities(form);

    form.find('#order_area_id').on('change', () => {
      Orders.fill_activities(form);
      Orders.fill_sub_activities(form);
    });

    form.find('select.activity-selector').on('change', () => {
      Orders.fill_sub_activities(form);
    });
  }

  static setupCharts() {
    if ($('#orders_chart').length) {
      Morris.Bar({
        element: 'orders_chart',
        data: $('#orders_chart').data('orders'),
        xkey: 'x_key',
        ykeys: ['order_count'],
        labels: ['Orders'],
        xLabelAngle: 65,
        hoverCallback(index, options, content, row) {
          if (window.location.pathname !== '/orders/intake_chart') {
            return "<div class='morris-hover-row-label'></div>" + row.x_key + "</div><div class='morris-hover-point' style='color: #0b62a4'> Orders:" + row.order_count + "</div><a class='btn btn-warning btn-sm' href='" + row.hover_path + "'>View</a>";
          } else {
            return "<div class='morris-hover-row-label'></div>" + row.x_key + "</div><div class='morris-hover-point' style='color: #0b62a4'> Orders:" + row.order_count + "</div>";
          }
        }
      });
    }

    $('.morris-hover').on('touchstart', 'a', function() {
      this.click();
    });
  }

  static setupOrderConsentForm() {
    const $form = $('form.order-consent-form');
    if ($form.length == 0) {
      return false;
    }

    Patients.setupPatientContactInfoForm();

    if ($form.find('input[name="payment_form[consultation_request]"].radio_buttons:checked').val() == 'true') {
      $form.find('.payment_form_consultation_request_note').toggle(true);
    }

    $form.find('input[name="payment_form[consultation_request]"].radio_buttons').on('click', function() {
      $form.find('.payment_form_consultation_request_note').toggle(this.value == 'true');
    });

    if ($form.find('input[name="payment_form[fill_consent]"].radio_buttons').length == 0) {
      $form.find('input[type=submit]').prop('disabled', false);
    } else {
      $form.find('input[name="payment_form[fill_consent]"].radio_buttons').on('click', function() {
        $form.find('input[type=submit]').prop('disabled', false);
      });
    }
  }

  static fill_activities(form = null) {
    const $form = form || $('select.activity-selector').closest('form');
    const areaId = Number($form.find('#order_area_id').val());
    const activityId = Number($form.find('select.activity-selector').val());

    $form.find('select.activity-selector option').each(function() {
      const $option = $(this);
      if ($option.val() == '') {
        return null;
      }

      if ($option.data('area-id') == areaId || $option.val() == 'All') {
        const selected = $option.val() == activityId || ($option.val() == 'All' && (isNaN(activityId) || activityId == 0));
        $option.removeClass('d-none').prop('selected', selected).prop('disabled', false);
      } else {
        $option.addClass('d-none').prop('selected', false).prop('disabled', true);
      }
    });
  }

  static fill_sub_activities(form = null) {
    const $form = form || $('select.activity-selector').closest('form');
    if ($form.find('#order_sub_activity_id').length == 0) {
      return null;
    }

    const activityId = Number($form.find('select.activity-selector').val());
    const subActivityId = Number($form.find('#order_sub_activity_id').val());

    $form.find('select#order_sub_activity_id option').each(function() {
      const $option = $(this);
      if ($option.val() == '') {
        return null;
      }

      if ($option.data('activity-id') == activityId || $option.val() == 'All') {
        const selected = $option.val() == subActivityId || ($option.val() == 'All' && (isNaN(subActivityId) || subActivityId == 0));
        $option.removeClass('d-none').prop('selected', selected).prop('disabled', false);
      } else {
        $option.addClass('d-none').prop('selected', false).prop('disabled', true);
      }
    });
  }

  static toggleRefillFields(form, toggle) {
    form.find('.refill_field').parents('.form-group').toggle(toggle);
    if (!toggle) {
      form.find('.refill_field').val(null);
    }
  }

  static toggleOrderOutcomeField(form, toggle) {
    form.find('.order_order_outcome').toggle(toggle);

    if (!toggle) {
      return form.find('#order_order_outcome_id option[value=""]').attr('selected', true);
    }
  }

  static maybeShowConsentGatheringDetails(form) {
    const gather_consent_activity_id = form.find('#order_activity_id').data('consent-gather-id');
    if (gather_consent_activity_id && (form.find('#order_activity_id').val() === gather_consent_activity_id.toString())) {
      return form.find('.consent-gathering-details').show();
    } else {
      return form.find('.consent-gathering-details').hide();
    }
  }

  static requireOrderOutcome(form) {
    const orderClosedInput = form.find('#order_closed');

    if (!orderClosedInput.length) {
      return false;
    }

    return orderClosedInput.is(':checked');
  }

  static showPrescriberLocation(form, toggle) {
    if (toggle) {
      return form.find('#order_prescriber_location_id').parents('.form-group').toggle(true);
    } else {
      form.find('#order_prescriber_location_id').parents('.form-group').toggle(false);
      return form.find('#order_location_prescriber_id').val(null);
    }
  }

  static select_all_column_checkboxes( element, checked ) {
    const parent_table = element.closest("table");
    const index = parent_table.find("th").index( element.closest("th") ) + 1;
    return parent_table.find("tr td:nth-child(" + index + ")").each(function() {
      const form_switch = $(this).find(".switch");
      if (!form_switch.prop('disabled')) {
        form_switch.prop( 'checked', checked );
        return form_switch.trigger("change");
      }
    });
  }

  static confirmClosure(form) {
    const $form = form;
    if (!$form.hasClass('dirty')) {
      return false;
    }

    const orderOutcome = $form.find('#order_order_outcome_id option:selected').closest('optgroup').prop('label');
    if (orderOutcome == 'Active') { // aka unsuccessful
      const patientCharges = parseFloat($form.find('#order_copay').val()) + parseFloat($form.find('#order_delivery_fee').val());
      const attorneyCharges = parseFloat($form.find('#order_lop_amount').val());

      if (patientCharges > 0 || attorneyCharges > 0) {
        let confirmPrompt = 'Are you sure? Closing the order unsuccessfully will wipe out the ';
        if (patientCharges > 0 && attorneyCharges > 0) {
          confirmPrompt += `patient charges $${patientCharges.toFixed(2)} and the attorney charges $${attorneyCharges.toFixed(2)}.`;
        } else if (patientCharges > 0) {
          confirmPrompt += `patient charges $${patientCharges.toFixed(2)}.`;
        } else if (attorneyCharges > 0) {
          confirmPrompt += `attorney charges $${attorneyCharges.toFixed(2)}.`;
        }

        if (window.confirm(confirmPrompt)) {
          $form.find('#order_copay, #order_delivery_fee, #order_lop_amount').val('');
        } else {
          const $orderOutcome = $form.find('#order_order_outcome_id');
          $orderOutcome.val($orderOutcome.data('val'));
        }
      }
    }
  }
}
