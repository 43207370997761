import $ from 'jquery';

export class PrescriptionRequests {
  static setup() {
    $('#prescription_request_special_note_template').on('change', (event) => {
      const selectedOption = event.target.value;
      const specialNoteTemplate = $(`#prescription_request_special_note_template option[value="${selectedOption}"]`);
      if (specialNoteTemplate) {
        $('#prescription_request_special_note').val(specialNoteTemplate.data('template-text'));
      }
    });

    $('#prescription_request_prescriber_id').data('original-value', $('#prescription_request_prescriber_id').val());
    $('#prescription_request_prescriber_id').change(function() {
      const prescriberId = Number($('#prescription_request_prescriber_id').val());
      if (prescriberId == Number($('#prescription_request_prescriber_id').data('original-value'))) {
        return false;
      }

      $('#prescription_request_prescriber_id').data('original-value', prescriberId);

      if (prescriberId > 0) {
        $.ajax({
          type: 'POST',
          url: '/orders/fill_prescriber_location',
          data: { prescriber_id: prescriberId },
          success(data) {
            if (data && (data !== '')) {
              $('.prescription_request_prescriber_location_id .prescriber-location-id').remove();
              $('.prescription_request_prescriber_location_id').append(data);
            }
          }
        });

        $.ajax({
          type: 'GET',
          url: `/prescribers/${prescriberId}.json`,
          success(data) {
            if (data && data.prescriber) {
              $('#prescription_request_send_by_id').val(data.prescriber.communication_method_id);
              $('#prescription_request_send_by_id option').each(function(option) {
                $(option).text($(option).text().replace('(prescriber\'s communication method)', ''));
              });
              if (! $('#prescription_request_send_by_id option:selected').text().endsWith('(prescriber\'s communication method)')) {
                $('#prescription_request_send_by_id option:selected').text($('#prescription_request_send_by_id option:selected').text() + ' (prescriber\'s communication method)');
              }
            }
          }
        });
      }
    });

    $('#refills').show();
    if ($('#prescription_request_request_type :selected').text() === 'Refill') {
      $('#last-refill').show();
      $('#date-written').show();
    }

    return $('#prescription_request_request_type').on('change', function() {
      if ($('#prescription_request_request_type :selected').text() === 'Refill') {
        $('#prescription_request_intro_text').val('Prescription Refill Request');
        $('#last-refill').show();
        $('#date-written').show();
      } else {
        $('#prescription_request_intro_text').val('');
        $('#prescription_request_last_refill').val('');
        $('#prescription_request_date_written').val('');
        $('#last-refill').hide();
        $('#date-written').hide();
      }
    });
  }
}
